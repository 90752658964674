
  import { defineComponent, ref } from "vue";
  import MenuTab from "@/components/common/MenuTab.vue";
  import { calendarFormat } from "@/utils/dateFormat";
  import list from "@/components/common/list.vue";
  import WxCard from "@/components/common/WxCard.vue";
  import BalanceItem from "@/components/common/BalanceItem.vue";
  import AccountItem from "@/components/common/AccountItem.vue";
  import { useRoute } from "vue-router";
  export default defineComponent({
    name: "Bill",
    components: {
      MenuTab,
      list,
      WxCard,
      BalanceItem,
      AccountItem
    },

    setup() {
      type Menu = "我的货款" | "我的余额";
      const menu: Menu[] = ["我的货款", "我的余额"];
      const route = useRoute();
      const type = ref(Number(route.query.type || 0));
      const showRedPackage = ref(
          localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      // const currentMenuItem = ref(menu[0]);
      const currentMenuItem = ref(menu[Number(type.value)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }
      return {
        menu,
        currentMenuItem,
        selectMenu,
        calendarFormat,
        type,
        showRedPackage,
      };
    },
  });
