<template>
  <main>
    <menu-tab :menu="menu" :slidesPerView="2" @change="selectMenu"></menu-tab>

    <wx-card url="/M/AokeNei/GetUserAccount" :show="currentMenuItem == menu[0]">
      <template v-slot:default="data">
        <account-item :accounts="data"></account-item>
      </template>
    </wx-card>

<!--    <list url="/M/User/MyBalance" :show="currentMenuItem == menu[1]">-->
<!--      <template v-slot:default="data">-->
<!--        <balance-item :myBalance="data"></balance-item>-->
<!--      </template>-->
<!--    </list>-->
    <list url="/M/AokeNei/GetUserBalance" :show="currentMenuItem == menu[1]">
      <template v-slot:default="data">
        <balance-item :myBalance="data"></balance-item>
      </template>
    </list>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import MenuTab from "@/components/common/MenuTab.vue";
  import { calendarFormat } from "@/utils/dateFormat";
  import list from "@/components/common/list.vue";
  import WxCard from "@/components/common/WxCard.vue";
  import BalanceItem from "@/components/common/BalanceItem.vue";
  import AccountItem from "@/components/common/AccountItem.vue";
  import { useRoute } from "vue-router";
  export default defineComponent({
    name: "Bill",
    components: {
      MenuTab,
      list,
      WxCard,
      BalanceItem,
      AccountItem
    },

    setup() {
      type Menu = "我的货款" | "我的余额";
      const menu: Menu[] = ["我的货款", "我的余额"];
      const route = useRoute();
      const type = ref(Number(route.query.type || 0));
      const showRedPackage = ref(
          localStorage.getItem("showRedPackage") ? true : false
      );
      localStorage.removeItem("showRedPackage");
      // const currentMenuItem = ref(menu[0]);
      const currentMenuItem = ref(menu[Number(type.value)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }
      return {
        menu,
        currentMenuItem,
        selectMenu,
        calendarFormat,
        type,
        showRedPackage,
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 110px;
  }
  .order-ul li {
    box-sizing: border-box;
    padding: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    border-bottom: 5.6px solid #f4f4f4;
    position: relative;
    padding-left: 70px;
  }

  .spend-date {
    position: absolute;
    left: 15px;
    // top: 20px;
    top: 38px;
    color: #666;
  }
  .spend-date span {
    font-size: 22px;
    text-align: center;
    display: block;
  }
  .spend-date p {
    font-size: 14px;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }

  .order-ul .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    color: #444;
    position: relative;
  }
  .price {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    color: rgb(102, 102, 102);
  }
  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 0 5px 0;
    padding-left: 10px;
    font-size: 14px;
    box-sizing: border-box;
    color: #666;
    position: relative;
  }

  .order-btn {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.25px;
    text-align: center;
    width: 80px;
    height: 22.5px;
    border-radius: 15px;

    line-height: 22.5px;

    font-size: 14px;
    color: #df0034;
    border: solid 1px #df0024;
  }

  .balance {
    position: fixed;
    color: #666;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    padding-left: 25px;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    z-index: 9;
  }
</style>
